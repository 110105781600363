<template>
  <div class="bgapp d-flex align-items-center min-vh-100">
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="4">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4">
              <CForm>
                <h1>Reset Password</h1>
                <p class="text-muted">
                  Please insert your email below, then we will send your new password to your inbox.
                </p>
                <CInput
                  placeholder="Email"
                  autocomplete="email"
                  v-model="email"
                  prepend="@"
                />
                <div class="form-group">
                  <!-- <slide-unlock
                      ref="vueslideunlock"
                      :auto-width="true"
                      :circle="true"
                      :disabled="false"
                      :noanimate="false"
                      :width="400"
                      :height="60"
                      text="slide to Capctha"
                      success-text="success"
                      @completed="onVerify()"
                  /> -->
                <vue-recaptcha ref="recaptcha" @verify="onVerify" @error="this.myCap = true" sitekey="6LfuC5UeAAAAABP6NoYPM3WcGo0f_Qo_4hhvqwM6" />
                </div>
                <CRow>
                    <CCol col="5" class="text-left">
                     <CButton color="link" to="/login" class="px-0"
                        >Just Login!</CButton>
                    </CCol>
                    <CCol col="7" class="text-right">
                      
                      <CButton color="success" v-on:click="updateNow" block
                        >Send Now</CButton>
                    </CCol>
                  </CRow>
              </CForm>
            </CCardBody>
            <!-- <CCardFooter class="p-4">
              <CRow>
                <CCol col="6">
                  <CButton block color="facebook"> Facebook </CButton>
                </CCol>
                <CCol col="6">
                  <CButton block color="twitter"> Twitter </CButton>
                </CCol>
              </CRow>
            </CCardFooter> -->
          </CCard>
        </CCol>
      </CRow>
    </CContainer>

    <CModal title="Warning" :show.sync="myCap" size="sm">
      Failed! Wrong Capctha
    </CModal>
    <CModal title="Warning" :show.sync="myModal2" size="sm">
      Failed! Reset Password failed, please check your internet connection...
    </CModal>
    <CModal title="Warning" :show.sync="myModal" size="sm">
      Failed! Please fill your email acount...
    </CModal>
    <CModal title="Sukses" color="success" :show.sync="mySukses" size="sm">
      Success! Account Password has been reset. Please check your inbox email
    </CModal>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { VueRecaptcha } from 'vue-recaptcha';
// import SlideUnlock from "vue-slide-unlock";
import axios from "axios";
export default {
  name: "Register",
  components: { VueRecaptcha },
  data() {
    return {
      tabs: [],
      myModal: false,
      myModal2: false,
      mySukses: false,
      myCap:false,
      users: "",
      countDown : 60,
      dismissCountDown: 0,
      country: "",
      nama: "",
      password: "",
      repassword: "",
      disableSend:false,
      email: "",
      telpon: "",
      rcvr: "",
      activeTab: 1,
      robot:false,
      affiliator : "950307",
    };
  },
  mounted(){
    this.cekRef();
    // this.sendPassword();   
    this.countDownTimer()
  
  },
  methods: {
    // validator (val) {
    //   return val ? val.length >= 4 : false
    // },
    cekRef: function (){
          let aff = this.$route.params.refer;
          if(aff){
            let aff2 = aff.split("=");
            this.affiliator = aff2['1'];
            // alert(this.affiliator);  
          }

         
    },
    countDownTimer() {
                if(this.countDown > 0) {
                    setTimeout(() => {
                        this.countDown -= 1
                        this.countDownTimer()
                    }, 1000)
                }
    },
    sendPassword: function (response) {
       const user = {
          nama: "",
          email: this.email,
        };

          axios
        .post(
          process.env.VUE_APP_BASE_URL+"api/public/index.php/updatepassword/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",user
        )
        .then((response) => {
          // alert(response);
          // this.members = response.data;
          // alert(this.members);
          this.$router.push("/login/");
        });
    },
    onVerify: function (response) {
      if (response) this.robot = true;
    },
    updateNow: function (event) {
      // Simple POST request with a JSON body using axios
      const user = {
        nama: "",
        email: this.email,
      };
      // alert(localStorage.getItem("jwt"));
      if(this.robot==true){
        if (this.email == null || this.email == "")
        {
          this.myModal = true;
        } 
        else {
            axios
              .post(
                process.env.VUE_APP_BASE_URL+"api/public/index.php/updatepassword/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
                user
              )
              .then((response) => {
                // console.log(response);
                // alert(JSON.stringify(response.data));
                // this.tabs = response.data;
                if (response.data == "sukses") {
                  
                  this.mySukses = true;
                  this.$router.push("/login/");

                  // this.myModal2 = true;
                } else {
                  // alert(JSON.stringify(user));
                  this.myModal2 = true;
                }
                this.robot=false;
              });
          
        }
      }
      else{
        this.myCap=true;
      }
    },
  },
};
</script>
